import React, { useState, useEffect, useReducer, useContext } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';
import Table from "@mui/material/Table";
import { Tooltip } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ShareIcon from "@mui/icons-material/Share";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import GetAppIcon from '@mui/icons-material/GetApp';
import { BiMessageRoundedAdd } from "react-icons/bi";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ShortcutMessageModal from "../../components/ShortcutMessageModal";
import ConfirmationModal from "../../components/ConfirmationModal/";
import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import ShortcutMessageImportModal from "../../components/ShortcutMessageImportModal";
import getSocket from "../../helpers/socket";
import contrastColor from "../../helpers/contrastColor";
import ForbiddenPage from "../../components/ForbiddenPage";

const socket = getSocket();


const reducer = (state, action) => {

    if (action.type === "LOAD_QUEUES") {
        const queues = action.payload;
        const newQueues = [];

        queues.forEach(queue => {
            const queueIndex = state.findIndex(q => q.id === queue.id);
            if (queueIndex !== -1) {
                state[queueIndex] = queue;
            } else {
                newQueues.push(queue);
            }
        });

        return [...state, ...newQueues];
    }


    if (action.type === "LOAD_SHORTCUT_MESSAGES") {
        const shortcutMessages = action.payload;
        const newShortcutMessages = [];

        shortcutMessages.forEach(shortcutMessage => {
            const index = state.findIndex(c => c.id === shortcutMessage.id);
            if (index !== -1) {
                state[index] = shortcutMessage;
            } else {
                newShortcutMessages.push(shortcutMessage);
            }
        });

        return [...state, ...newShortcutMessages];
    }



    if (action.type === "UPDATE_SHORTCUT_MESSAGES") {
        const shortcutMessage = action.payload;
        const index = state.findIndex(c => c.id === shortcutMessage.id);

        if (index !== -1) {
            state[index] = shortcutMessage;
            return [...state];
        } else {
            return [shortcutMessage, ...state];
        }
    }

    if (action.type === "DELETE_SHORTCUT_MESSAGES") {
        const shortcutMessageId = action.payload;

        const index = state.findIndex(c => c.id === shortcutMessageId);
        if (index !== -1) {
            state.splice(index, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        marginTop: 40,
        borderRadius: 20,
        border: '0px !important',
        marginBottom: 40,
        overflow: 'hidden'
    },
    mainPaperTable: {
        flex: 1,
        overflow: 'auto',
        height: '68vh',
        ...theme.scrollbarStylesSoft,
    },
    helpMessage: {
        padding: "10px"
    },
    ticketQueueColor: {
        flex: "none",
        width: "8px",
        height: "100%",
        position: "absolute",
        top: "0%",
        left: "0%",
    },
    queuesList: {
        display: 'flex',
        flexDirection: 'row'
    },
    queueTag: {
        background: "#FCFCFC",
        color: "#000",
        marginRight: 5,
        padding: 1,
        fontWeight: 'bold',
        paddingLeft: 5,
        paddingRight: 5,
        borderRadius: 3,
        fontSize: "0.8em",
        whiteSpace: "nowrap"
    },
    buttonsTicket: {
        height: 40,
        borderRadius: '5px!important',
        display: 'inline-flex',
        alignItems: 'center',
        boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.07) !important',
        '&:hover': {
            boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.2) !important',
        },
    }
}));

const ShortcutMessages = () => {
    const classes = useStyles();
    const history = useHistory();

    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [queues, dispatchQueues] = useReducer(reducer, []);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [shortcutMessages, dispatch] = useReducer(reducer, []);
    const [selectedShortcutMessageId, setSelectedShortcutMessageId] = useState(null);
    const [shortcutMessageModalOpen, setShortcutMessageModalOpen] = useState(false);
    const [shortcutMessageImportModalOpen, setShortcutMessageImportModalOpen] = useState(false);
    const [deletingShortcutMessage, setDeletingShortcutMessage] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [showAllTextId, setShowAllTextId] = useState(null);
    const [exporting, setExporting] = useState(false);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const { data } = await api.get("/queue");
                dispatchQueues({ type: "LOAD_QUEUES", payload: data });

                setLoading(false);

            } catch (err) {
                toastError(err);
                setLoading(false);
            }
        })();
    }, []);



    useEffect(() => {

    }, [user]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchShortcutMessages = async () => {
                try {
                    let queueIds;
                    if (user.queues) {
                        queueIds = user.queues.map(queue => queue.id);
                    }
                    const { data } = await api.get("/shortcut-messages/", {
                        params: { searchParam, pageNumber, queueIds }
                    });

                    let filteredShortcuts;
                    if (data.shortcutMessages) {
                        filteredShortcuts = data.shortcutMessages;
                        //console.log(filteredShortcuts, 'test')

                        dispatch({ type: "LOAD_SHORTCUT_MESSAGES", payload: filteredShortcuts });
                        setHasMore(data.hasMore);

                        setLoading(false);
                    }
                } catch (err) {
                    toastError(err);
                }
            };
            fetchShortcutMessages();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber, user]);

    useEffect(() => {
        const shortcutMessageEvent = data => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_SHORTCUT_MESSAGES", payload: data.shortcutMessage });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_SHORTCUT_MESSAGES", payload: +data.shortcutMessageId });
            }
        }

        socket.on("shortcutMessage", shortcutMessageEvent);

        return () => {
            socket.off("shortcutMessage", shortcutMessageEvent);
        };
    }, []);

    const handleSearch = event => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleOpenShortcutMessageModal = () => {
        setSelectedShortcutMessageId(null);
        setShortcutMessageModalOpen(true);
    };

    const handleCloseShortcutMessageModal = () => {
        setSelectedShortcutMessageId(null);
        setShortcutMessageModalOpen(false);
    };

    const hadleEditShortcutMessage = shortcutMessageId => {
        setSelectedShortcutMessageId(shortcutMessageId);
        setShortcutMessageModalOpen(true);
    };

    const handleDeleteShortcutMessage = async shortcutMessageId => {
        try {
            await api.delete(`/shortcut-messages/${shortcutMessageId}`);
            toast.success(i18n.t("shortcut_messages.toasts.deleted"));
        } catch (err) {
            toastError(err);
        }
        setDeletingShortcutMessage(null);
        setSearchParam("");
        setPageNumber(1);
    };

    const loadMore = () => {
        setPageNumber(prevState => prevState + 1);
    };

    const handleScroll = e => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const renderShortcutMessageContent = (shortcutMessage) => {
        if (shortcutMessage.content) {
            if (showAllTextId == shortcutMessage.id) {
                return (
                    <div>
                        {shortcutMessage.content} <a onClick={() => setShowAllTextId(null)}>{i18n.t("shortcut_messages.showLess")}</a>
                    </div>
                );
            } else {
                return (
                    <div>
                        {shortcutMessage.content.substring(0, 100) + (shortcutMessage.content.length > 100 ? '... ' : ' ')}
                        {shortcutMessage.content.length > 100 ? <a onClick={() => setShowAllTextId(shortcutMessage.id)}>{i18n.t("shortcut_messages.showMore")}</a> : ''}
                    </div>
                );
            }
        } else {
            return '';
        }
    };

    const handleOpenShortcutMessageImportModal = async () => {
        setShortcutMessageImportModalOpen(true);
    }

    const handleCloseShortcutMessageImportModal = async () => {
        setShortcutMessageImportModalOpen(false);
    }

    const handleExport = async () => {
        setExporting(true);
        try {
            const { data } = await api.get("/shortcut-messages/export", { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json', 'Accept': 'application/octet-stream' } });
            const blob = new Blob([data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "shortcut-messages.onecode";
            link.click();
            setExporting(false);
        } catch (err) {
            toastError(err);
            setExporting(false);
        }
    }

    return (
        <MainContainer className={classes.mainContainer}>
            <ShortcutMessageModal
                open={shortcutMessageModalOpen}
                onClose={handleCloseShortcutMessageModal}
                aria-labelledby="form-dialog-title"
                shortcutMessageId={selectedShortcutMessageId}
            ></ShortcutMessageModal>
            <ShortcutMessageImportModal
                open={shortcutMessageImportModalOpen}
                onClose={handleCloseShortcutMessageImportModal}
                aria-labelledby="Importar mensagens pré-definidas"
            ></ShortcutMessageImportModal>
            <ConfirmationModal
                title={
                    deletingShortcutMessage
                        ? `${i18n.t("shortcut_messages.confirmationModal.deleteTitle")} ${deletingShortcutMessage.name
                        }?`
                        : `${i18n.t("shortcut_messages.confirmationModal.importTitlte")}`
                }
                open={confirmOpen}
                onClose={setConfirmOpen}
                onConfirm={e =>
                    deletingShortcutMessage
                        ? handleDeleteShortcutMessage(deletingShortcutMessage.id)
                        : null
                }
            >
                {deletingShortcutMessage
                    ? `${i18n.t("shortcut_messages.confirmationModal.deleteMessage")}`
                    : `${i18n.t("shortcut_messages.confirmationModal.importMessage")}`}
            </ConfirmationModal>
            <Can
                role={user.roleId}
                perform="shortcut-messages:show:page"
                yes={() => (
                    <Paper
                        className={classes.mainPaper}
                        variant="outlined"
                        onScroll={handleScroll}
                    >
                        <MainHeader style={{ marginBottom: 0, paddingBottom: 0 }}>
                            <Title><BiMessageRoundedAdd size={24} /> {i18n.t("shortcut_messages.title")}</Title>
                            <MainHeaderButtonsWrapper>
                                <TextField
                                    placeholder={i18n.t("shortcut_messages.searchPlaceholder")}
                                    type="search"
                                    value={searchParam}
                                    size="small"
                                    onChange={handleSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon style={{ color: "gray" }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Can
                                    role={user.roleId}
                                    perform="shortcut-messages:create"
                                    yes={() => (
                                        <Button
                                            variant="contained"
                                            className={classes.buttonsTicket}
                                            color="primary"
                                            style={{ color: '#00000', fontSize: '12px' }}
                                            onClick={handleOpenShortcutMessageModal}
                                        >
                                            <AddIcon style={{ fontSize: 13 }} />  {i18n.t("shortcut_messages.buttons.add")}
                                        </Button>)}
                                    no={() => <></>}
                                />
                                <Can
                                    role={user.roleId}
                                    perform="shortcut-messages:export"
                                    yes={() => (
                                        <Button
                                            variant="contained"
                                            className={classes.buttonsTicket}
                                            style={{ backgroundColor: 'black', color: '#ffffff', fontSize: '12px' }}
                                            onClick={handleExport}
                                            disabled={exporting}
                                        >
                                            <ShareIcon style={{ fontSize: 13 }} /> {exporting ? 'Exportando...' : i18n.t("shortcut_messages.buttons.export")}
                                        </Button>)}
                                    no={() => <></>}
                                />
                                <Can
                                    role={user.roleId}
                                    perform="shortcut-messages:import"
                                    yes={() => (
                                        <Button
                                            variant="contained"
                                            className={classes.buttonsTicket}
                                            color="error"
                                            style={{ color: '#ffffff', fontSize: '12px' }}
                                            onClick={handleOpenShortcutMessageImportModal}
                                        >
                                            <GetAppIcon style={{ fontSize: 13 }} /> {i18n.t("shortcut_messages.buttons.import")}
                                        </Button>)}
                                    no={() => <></>}
                                />
                            </MainHeaderButtonsWrapper>
                        </MainHeader>
                        <p className={classes.helpMessage}>
                            {i18n.t("shortcutMessageModal.title.info")}
                        </p>
                        <Paper
                            className={classes.mainPaperTable}
                            variant="outlined"
                            onScroll={handleScroll}
                        >
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{i18n.t("shortcut_messages.table.name")}</TableCell>
                                        <TableCell>
                                            {i18n.t("shortcut_messages.table.content")}
                                        </TableCell>
                                        <TableCell>
                                            {i18n.t("shortcut_messages.table.file")}
                                        </TableCell>
                                        <TableCell>
                                            {i18n.t("shortcut_messages.table.queue")}
                                        </TableCell>
                                        <TableCell>
                                            {i18n.t("shortcut_messages.table.actions")}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <>
                                        {shortcutMessages.map(shortcutMessage => (
                                            <TableRow key={shortcutMessage.id}>
                                                <TableCell>{shortcutMessage.name}</TableCell>
                                                <TableCell>{renderShortcutMessageContent(shortcutMessage)}</TableCell>

                                                <TableCell>
                                                    {shortcutMessage.mediaUrl && (
                                                        <a href={`${shortcutMessage.absoluteMediaUrl}`} target="_blank">
                                                            <img src={`${shortcutMessage.absoluteMediaUrl}`} style={{ maxWidth: 32, maxHeight: 32 }} />
                                                        </a>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <div className={classes.queuesList}>
                                                        {queues.map((queue, index) => {
                                                            if (shortcutMessage.queueIds) {
                                                                const queueIdsArray = shortcutMessage.queueIds.split(',').map(id => id.trim());
                                                                const exactMatch = queueIdsArray.includes(String(queue.id));

                                                                if (exactMatch) {
                                                                    return (
                                                                        <div key={index} style={{ backgroundColor: queue.color, color: contrastColor(queue.color) }}
                                                                            className={classes.queueTag}>{queue.name}</div>
                                                                    );
                                                                }
                                                            }
                                                            return null;
                                                        })}
                                                    </div>
                                                </TableCell>

                                                <TableCell align="center">
                                                    <Can
                                                        role={user.roleId}
                                                        perform="shortcut-messages:edit:any"
                                                        yes={() => (
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => hadleEditShortcutMessage(shortcutMessage.id)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>)}
                                                        no={() => <></>}
                                                    />
                                                    <Can
                                                        role={user.roleId}
                                                        perform="shortcut-messages:delete:any"
                                                        yes={() => (
                                                            <IconButton
                                                                size="small"
                                                                onClick={e => {
                                                                    setConfirmOpen(true);
                                                                    setDeletingShortcutMessage(shortcutMessage);
                                                                }}
                                                            >
                                                                <DeleteOutlineIcon />
                                                            </IconButton>
                                                        )}
                                                        no={() => <></>}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {loading && <TableRowSkeleton avatar columns={2} />}
                                    </>
                                </TableBody>
                            </Table>
                        </Paper>
                    </Paper>
                )}
                no={() => <>
                    <ForbiddenPage />
                </>}
            />

        </MainContainer>
    );
};

export default ShortcutMessages;
