import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import clsx from "clsx";

import { IconButton, Paper } from "@mui/material";
import { FaTimes } from "react-icons/fa";
import makeStyles from '@mui/styles/makeStyles';

import ContactDrawer from "../ContactDrawer";
import MessageInput from "../MessageInput/";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import TicketActionButtons from "../TicketActionButtons";
import MessagesList from "../MessagesList";
import api from "../../services/api";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { EditMessageProvider } from "../../context/EditingMessage/EditingMessageContext";
import toastError from "../../errors/toastError";
import Select from 'react-select';
import colourStyles from "../../constants/tagsColor";
import getSocket from "../../helpers/socket";
import TicketInfoDrawer from "../TicketInfoDrawer";
import { InfoOutlined } from "@mui/icons-material";
import { AuthContext } from "../../context/Auth/AuthContext";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import IntegrationIframeDrawer from "../IntegrationIframe/drawer";
import { Can } from "../Can";

const socket = getSocket();

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        height: "85vh",
        position: "relative",
        // overflow: "hidden",
    },

    fullscreen: {
        position: 'absolute'
    },
    ticketInfo: {
        maxWidth: "35%",
        flexBasis: "35%",
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            maxWidth: "80%",
            flexBasis: "80%"
        }
    },
    ticketActionButtons: {
        maxWidth: "65%",
        flexBasis: "65%",
        display: "flex",
        [theme.breakpoints.down('md')]: {
            maxWidth: "100%",
            flexBasis: "100%",
            marginBottom: "0"
        }
    },

    mainWrapper: {
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        border: 0,
        overflow: "hidden",
        borderRadius: 20,
        boxShadow: '0px 0px 25px 0px rgba(0, 0, 0, 0.05)',
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },

    mainWrapperShift: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
}));

const Ticket = ({ fullscreen, setFullscreen, closeModal }) => {
    const { ticketId } = useParams();
    const history = useHistory();
    const classes = useStyles();

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [infoDrawerOpen, setInfoDrawerOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [contact, setContact] = useState({});
    const [ticket, setTicket] = useState({});
    const [tags, setTags] = useState([]);
    const [initialTags, setInitialTags] = useState([]);
    const [integrationIframes, setIntegrationIframes] = useState([]);
    const [loadedIframe, setLoadedIframe] = useState(null);
    const [dragDropFiles, setDragDropFiles] = useState([]);
    const { user } = useContext(AuthContext);
    const { isActive } = useContext(SettingsContext);
	const marqueeActived = isActive('enableMarqueeContactName');

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchTicket = async () => {
                try {
                    const { data } = await api.get("/tickets/" + ticketId);
                    if (data.contact) {
                        setContact(data.contact);
                    }
                    if (data.contact.tags && data.contact.tags.length > 0) {
                        setInitialTags(data.contact.tags.map((tag) => { return { label: tag.name, value: tag.id, color: tag.color } }));
                    } else {
                        setInitialTags([]);
                    }
                    setTicket(data);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };
            fetchTicket();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [ticketId, history]);

    useEffect(() => {
        const connectEvent = () => {
            socket.emit("joinChatBox", ticketId);
        }

        const ticketEvent = data => {
            if (data.action === "update" && (data.ticket.id === Number(ticketId))) {
                //console.log('data update ticketEvent', data)   
                setTicket(data.ticket);
            }

            if (data.action === "delete" && (data.ticketId === Number(ticketId))) {
                //console.log(data, data.action)
                toast.success("Atendimento atualizado com sucesso!");
                history.push("/tickets");
            }

        }

        const contactEvent = data => {
            if (data.action === "update") {
                setContact(prevState => {
                    if (prevState.id === data.contact?.id) {
                        return { ...prevState, ...data.contact };
                    }
                    return prevState;
                });
            }
        }

        socket.on("connect", connectEvent);
        socket.on("ticket", ticketEvent);
        socket.on("contact", contactEvent);

        if (socket.connected) {
            connectEvent();
        }

        return () => {
            socket.emit("leaveChatBox", ticketId);
            socket.off("connect", connectEvent);
            socket.off("ticket", ticketEvent);
            socket.off("contact", contactEvent);
            //socket.disconnect();
        };//
    }, [ticketId]);


    // Load iframe integrations
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchIntegrations = async () => {
                try {
                    const { data } = await api.get("/integration/iframes");
                    setIntegrationIframes(data.iframes);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchIntegrations();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [ticketId, history]);




    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchTags = async () => {
                try {
                    const { data } = await api.get("/tags", { params: { all: true } });

                    setTags(data.tags);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchTags();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [ticketId, history]);

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleInfoDrawerOpen = () => {
        setInfoDrawerOpen(true);
    }

    const handleInfoDrawerClose = () => {
        setInfoDrawerOpen(false);
    }


    const handleFullscreen = () => {
        setFullscreen(!fullscreen)
    }


    const handleChangeTags = async (changedTags) => {
        setInitialTags(changedTags);
        try {
            await api.post(`/tags/change`, { tagIds: changedTags.map((tag) => tag.value), contactIds: [ticket.contact.id] });
        } catch (err) {
            toastError(err);
        }
    };

    const handleIframeClick = (iframe) => {
        setLoadedIframe(iframe);
    }

    const handleIframeClose = () => {
        setLoadedIframe(null);
    }

    const handleCloseModal = () => {
        closeModal();
    }

    return (
        <div className={`mainListMessages ${classes.root}`} id="drawer-container">

            {closeModal && <button className="closebutton" onClick={handleCloseModal}><FaTimes /></button>}
            <Paper
                variant="outlined"
                elevation={0}
                className={clsx(classes.mainWrapper, {
                    [classes.mainWrapperShift]: drawerOpen || infoDrawerOpen,
                })}
            >
                <TicketHeader loading={loading} handleFullscreen={handleFullscreen} fullscreen={fullscreen}>
                <div className={`ticketInfo ${classes.ticketInfo} ${!marqueeActived ? 'col-50' : 'col-35'}`}>
                        <TicketInfo
                            contact={contact}
                            ticket={ticket}
                            onClick={handleDrawerOpen}
                            infoButton={user.profile === 'admin' ? <IconButton
                                size="small"
                                onClick={handleInfoDrawerOpen}
                            ><InfoOutlined /></IconButton> : null}
                        />
                    </div>
                    <div className={classes.ticketActionButtons}>
                        <TicketActionButtons ticket={ticket} handleFullscreen={handleFullscreen} fullscreen={fullscreen} integrationIframes={integrationIframes} onLoadIframe={handleIframeClick} />
                    </div>
                </TicketHeader>
                <Can
                    role={user.roleId}
                    perform="tags:add"
                    yes={() => (
                        <div className={classes.ticketAddTags}>
                            <Select
                                options={tags.map((tag) => { return { value: tag.id, label: tag.name, color: tag.color } })}
                                isMulti
                                value={initialTags}
                                placeholder="Selecione as tags..."
                                onChange={handleChangeTags}
                                className="basic-multi-select tag-select"
                                classNamePrefix="select"
                                menuPosition={'fixed'}
                                menuPortalTarget={document.body}
                                styles={colourStyles}
                            />
                        </div>
                    )}
                    no={() =>
                        <div className={classes.ticketAddTags}>
                            <Select
                                options={tags.map((tag) => { return { value: tag.id, label: tag.name, color: tag.color } })}
                                isMulti
                                value={initialTags}
                                placeholder="Selecione as tags..."
                                onChange={handleChangeTags}
                                className="basic-multi-select tag-select"
                                classNamePrefix="select"
                                menuPosition={'fixed'}
                                menuPortalTarget={document.body}
                                styles={colourStyles}
                                isDisabled={!(user.permissions && user.permissions.includes('tags:add'))}
                            />
                        </div>
                    }
                />
                <ReplyMessageProvider>
                    <EditMessageProvider>
                        <MessagesList
                            ticketId={ticketId}
                            isGroup={ticket.isGroup}
                            status={ticket.status}
                            onDrop={setDragDropFiles}
                            user={user}
                        ></MessagesList>
                        <MessageInput ticketStatus={ticket.status} droppedFiles={dragDropFiles} contact={ticket && ticket.contact && ticket.contact} />
                    </EditMessageProvider>
                </ReplyMessageProvider>
            </Paper>
            {drawerOpen &&
                <ContactDrawer
                    open={drawerOpen}
                    handleDrawerClose={handleDrawerClose}
                    contact={contact}
                    loading={loading}
                    ticketId={ticket.id}
                    groupInfo={ticket.groupInfo}
                />}
            {infoDrawerOpen && <TicketInfoDrawer
                open={infoDrawerOpen}
                handleDrawerClose={handleInfoDrawerClose}
                ticket={ticket}
                loading={loading}
            />}
            {loadedIframe && <IntegrationIframeDrawer integrationIframe={loadedIframe} ticket={ticket} handleDrawerClose={handleIframeClose} />}
        </div>
    );
};

export default Ticket;
